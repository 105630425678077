.why_us {
  padding: 0 2rem;
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 10rem;
  display: flex;
  @media screen and (max-width: 1255px) {
    flex-direction: column-reverse;
    margin-top: 5rem;
  }
}

.why_us .illustrator {
  flex: 1;
  display: flex;
  justify-content: center;
}

.why_us .illustrator img {
  max-width: 100%;
}

.why_us .content {
  flex: 1;
}

.why_us .texts {
  font-size: 1.5rem;
  color: white;
  margin-top: 2rem;
}

.why_us li::marker {
  content: "✒️ ";
}

.why_us li {
  margin: 1rem 0;
}