.banner {
    height: 80vh;
    position: relative;
}

.banner .overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.432);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner .overlay > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 80%;
}

.banner .quote_words {
    font-size: 3rem;
    font-weight: 700;
    margin-right: 0.5rem;
    color: white;
}

.banner .next {
  right: 10px;
}

.banner .prev {
  left: 10px;
  transform: scale(-1);
}

.banner img {
  position: absolute;
  width: 100%;
  height: 80vh;
  object-fit: cover;
}