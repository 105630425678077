#home {
  margin-bottom: 30px;
}
#about {
  margin-top: 30px;
}
#gallery {
  margin-top: 30px;
}
#contact {
  margin-top: 40px;
}
@media screen and (max-width: 320px) {
  #about {
    margin-top: 45px;
  }
  #gallery {
    margin-top: 45px;
  }
  #contact {
    margin-top: 45px;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  #about {
    margin-top: 45px;
  }
  #gallery {
    margin-top: 45px;
  }
  #contact {
    margin-top: 45px;
  }
}
@media screen and (min-width: 480px) {
  #about {
    margin-top: 5rem;
  }
  #gallery {
    margin-top: 5rem;
  }
  #contact {
    margin-top: 5rem;
  }
}
