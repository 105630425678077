  
  .header nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 5;
  }
  
  .header .background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
    z-index: 4;
  }
  
  .header button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: fixed;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    z-index: 5;
  }
  
  .header ul,
  .header li {
    margin: 0;
    padding: 0;
    z-index: 5;
  }
  
  .header ul {
    padding: 25px;
    position: fixed;
    top: 100px;
    width: 230px;
    z-index: 5;
  }
  
  .header li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 5;
  }
  
  .header .nav_item {
    font-weight: 500;
    font-size: 1.5rem;
    background: linear-gradient(to right, #f32170, #ff6b08,black);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    z-index: 5;
  }
  
  .header .refresh {
    padding: 10px;
    position: fixed;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 5;
  }
  