.banner_image {
  width: 100%;
}
.principal {
  display: flex;
  gap: 40px;
  border: 5px solid red;
}
.teachimageContainer {
  width: 200px;
  height: 20px;
}
.teachImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 320px) {
  #home_banner {
    width: 100%;
    height: 30vh;
  }
  .banner_image {
    width: 100%;
    height: auto;
    background-size: contain;
    display: block;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  #home_banner {
    width: 100%;
    /* height: 80vh; */
  }
  .banner_image {
    /* height: auto; */
    /* background-size: contain; */
    display: block;
  }
}
