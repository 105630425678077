.aims {
  padding: 0 2rem;
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 10rem;
  display: flex;
  @media screen and (max-width: 1255px) {
    flex-direction: column-reverse;
    margin-top: 5rem;
  }
}

.aims .content {
  flex: 1;
}

.aims .texts {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: white;
}

.aims .illustrator {
  flex: 1;
  display: flex;
  justify-content: center;
}

.aims .illustrator img {
  width: 80%;
}

.aims li::marker {
  content: "🎯 ";
}

.aims li {
  margin: 1rem 0;
}