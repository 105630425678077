.gallery {
  padding: 0 2rem;
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 10rem;
  @media screen and (max-width: 1255px) {
    margin-top: 5rem;
  }
}

.gallery .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery .content img {
  width: 350px;
  height: 250px;
  margin: 1rem;
  border-radius: 1rem;
  object-fit:cover;
}

.gallery .heading_wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}