.welcome_container {
  padding: 0 2rem;
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 5rem;
}

.welcome_heading {
  display: flex;
  justify-content: center;
}

.welcome_heading h1{
  background: linear-gradient(to right, #f32170, #ff6b08,white);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

.welcome_content {
  margin-top: 2rem;
}

.welcome_content p {
  font-size: 1.5rem;
  color: white;
}