.dir_container {
  padding: 0 2rem;
  max-width: 1700px;
  margin: 0 auto;
  margin-top: 10rem;
  display: flex;
  @media screen and (max-width: 1255px) {
    flex-direction: column;
    margin-top: 5rem;
  }
}

.dir_content {
  flex: 1;
}

.dir_texts {
  margin-top: 2rem;
  font-size: 1.5rem;
  color: white;
}

.dir_illustration_container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.director_heading {
  display: flex;
}

.dir_illustration_container img{
  max-width: 100%;
}

